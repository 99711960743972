<template>
  <section class="pb-2">
    <validation-observer
      #default="{ handleSubmit, invalid, pristine }"
      ref="refFormObserver"
    >
      <!-- SECTION Form -->
      <b-form @submit.prevent="handleSubmit(nextHandle)">
        <!-- SECTION Card agency basic info -->
        <b-card
          header-tag="header"
          border-variant="info"
          header-bg-variant="light-info"
          header-class="py-1"
          class="border mt-1"
        >
          <!-- ANCHOR header -->
          <template #header>
            <h6 class="m-0">
              {{ $t('promotionAg.create.title') }}
            </h6>
          </template>
          <b-card-body class="p-0">
            <b-row class="mt-1 mb-3">
              <!-- ANCHOR Code -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.code')"
                  rules="code|length:9"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.code') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.code"
                      maxlength="9"
                      minlength="9"
                      lazy-formatter
                      :disabled="true"
                      :formatter="upperCaseFormatter"
                      :placeholder="$t('promotionAg.placeholder.code')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Name -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.name')"
                  rules=""
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.name') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.name"
                      maxlength="100"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.name')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR promotionType -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.promotionType') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.paidType"
                    style="font-size: 1rem;"
                    :options="typeOfDiscountPaidOptions"
                    :clearable="false"
                    :disabled="false"
                    :reduce="val => val.value"
                  >
                    <template #option="data">
                      <span>
                        {{ $t(`promotionAg.${data.label}`) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span>
                        {{ $t(`promotionAg.${data.label}`) }}
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR promotionType -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.promotionStrategy') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.strategy"
                    style="font-size: 1rem;"
                    :options="typeOfDiscountStrategyOptions"
                    :clearable="false"
                    :disabled="false"
                    label="label"
                    :reduce="val => val.value"
                  >
                    <template #option="data">
                      <span>
                        {{ $t(`promotionAg.${data.label}`) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span>
                        {{ $t(`promotionAg.${data.label}`) }}
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR discountAmount -->
              <b-col
                v-if="promotionDataToAdd.discountType === 'VND'"
                md="2"
                style="min-width: 20%;"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.discount')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.discount') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.discountAmount"
                      v-remove-non-numeric-chars.allNumber
                      type="number"
                      number
                      :state="getValidationState(validationContext) === false ? false : null"
                      maxlength="30"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.discountAmount')"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR discountPercent -->
              <b-col
                v-else
                md="2"
                style="min-width: 20%;"
              >
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.discount')"
                  rules="required|maxValue:100|min_value:0"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.discount') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.discountPercent"
                      v-remove-non-numeric-chars.allNumber
                      type="number"
                      number
                      :state="getValidationState(validationContext) === false ? false : null"
                      max="100"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.discountPercent')"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR discountType -->
              <b-col md>
                <b-form-group>
                  <template #label>
                    <div style="opacity: 0;">
                      {{ "-" }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.discountType"
                    style="font-size: 1rem;"
                    :options="typeOfDiscountOptions"
                    :clearable="false"
                    :reduce="val => val.value"
                    @input="handleChangeDiscountType"
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR Start date -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.startTime')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.startTime') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotionDataToAdd.startTime"
                      :locale="$i18n.locale"
                      :placeholder="$t('placeholderSelect')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="new Date()"
                      :max="promotionDataToAdd.endTime ? new Date(promotionDataToAdd.endTime) : null"
                      show-decade-nav
                      hide-header
                      boundary="window"
                      no-flip
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR End date -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.endTime')"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.endTime') }}
                        <span class="text-danger">(*)</span>
                      </div>
                    </template>
                    <b-form-datepicker
                      v-model="promotionDataToAdd.endTime"
                      :locale="$i18n.locale"
                      :placeholder="$t('placeholderSelect')"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      :min="promotionDataToAdd.startTime ? new Date(promotionDataToAdd.startTime) : new Date()"
                      show-decade-nav
                      hide-header
                      boundary="window"
                      no-flip
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <!-- ANCHOR minimum Passenger -->
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  :name="$t('promotionAg.minimumPassenger')"
                  rules=""
                >
                  <b-form-group>
                    <template #label>
                      <div>
                        {{ $t('promotionAg.minimumPassenger') }}
                      </div>
                    </template>
                    <b-form-input
                      v-model="promotionDataToAdd.minimumPassenger"
                      v-remove-non-numeric-chars.allNumber
                      number
                      maxlength="5"
                      lazy-formatter
                      :formatter="trimInput"
                      :placeholder="$t('promotionAg.placeholder.minimumPassenger')"
                      :state="getValidationState(validationContext) === false ? false : null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- ANCHOR Quota -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.quotaAmount') }}
                    </div>
                  </template>
                  <b-form-input
                    v-model="promotionDataToAdd.quotaAmount"
                    v-remove-non-numeric-chars.allNumber
                    number
                    type="number"
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('promotionAg.placeholder.quotaAmount')"
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR quotaCounter -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.quotaCounter') }}
                    </div>
                  </template>
                  <b-form-input
                    v-model="promotionDataToAdd.quotaCounter"
                    v-remove-non-numeric-chars.allNumber
                    type="number"
                    number
                    maxlength="30"
                    lazy-formatter
                    :formatter="trimInput"
                    :placeholder="$t('promotionAg.placeholder.quotaCounter')"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- ANCHOR agencies apply -->
              <b-col md="4">
                <SearchAgenciesSelect
                  :label="'agencyApply'"
                  :placeholder="'promotionAg.allAgencies'"
                  @updateValue="val => { promotionDataToAdd.agencies = cloneDeep(val) }"
                />
              </b-col>

              <!-- ANCHOR agencies declined -->
              <b-col md="4">
                <SearchAgenciesSelect
                  :label="'agenciesDeclined'"
                  :placeholder="'placeholderSelect'"
                  @updateValue="val => { promotionDataToAdd.excludedAgencies = cloneDeep(val) }"
                />
              </b-col>

              <!-- ANCHOR operator -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.operator') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.operator"
                    style="font-size: 1rem;"
                    multiple
                    push-tags
                    :close-on-select="false"
                    :taggable="enableTaggable"
                    :options="airlineFilterOptions"
                    :create-option="createValidAirlineOption"
                    :reduce="val => val.value"
                    :placeholder="$t('promotionAg.placeholder.operator')"
                    class="v-select-wrap"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        ref="refSearchOrInputCodeshare"
                        v-model="codeshare"
                        v-bind="attributes"
                        maxlength="2"
                        class="vs__search"
                        oninput="this.value = this.value.toUpperCase()"
                        v-on="events"
                        @keydown.enter="onEnterCodeshare()"
                      >
                    </template>
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between">
                        <span
                          class="d-block text-nowrap"
                          :class="{ 'font-weight-bold text-dark': !allVnAirlines.includes(data.value) }"
                        >
                          {{ data.label }}
                        </span>
                      </div>
                    </template>
                    <template #option="data">
                      <div
                        class="d-flex-center justify-content-between"
                        :class="{ 'font-weight-bold text-dark': !allVnAirlines.includes(data.value) }"
                      >
                        <span class="d-block text-nowrap">
                          {{ data.label }}
                        </span>
                      </div>
                    </template>
                    <template #spinner="data">
                      <div
                        v-if="data.loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('promotionAg.notExistCodeshare') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR Airline -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.airline') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.airline"
                    style="font-size: 1rem;"
                    :options="airlineFilterOptionsWithAllAirlines"
                    multiple
                    :close-on-select="false"
                    :reduce="val => val.value"
                    :placeholder="$t('promotionAg.allAirlines')"
                    class="v-select-wrap"
                  >
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class="d-block text-nowrap">
                          {{ data.value ? data.label : $t(`promotionAg.${data.label}`) }}
                        </span>
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class=" d-block text-nowrap">
                          {{ data.value ? data.label : $t(`promotionAg.${data.label}`) }}
                        </span>
                      </div>
                    </template>
                    <template #spinner="data">
                      <div
                        v-if="data.loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR Source -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.source') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.source"
                    style="font-size: 1rem;"
                    :options="sourceFilterOptionsWithAllSources"
                    multiple
                    :close-on-select="false"
                    :reduce="val => val.value"
                    :loading="loadingSources"
                    :placeholder="$t('promotionAg.allAirlines')"
                    class="v-select-wrap"
                  >
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class="d-block text-nowrap">
                          {{ data.value
                            ? ($te(`flight.airlines.${data.label}`)
                              ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})`
                              : data.label )
                            : $t(`promotionAg.${data.label}`) }}
                        </span>
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class=" d-block text-nowrap">
                          {{ data.value
                            ? ($te(`flight.airlines.${data.label}`)
                              ? `${$t(`flight.airlines.${data.label}`)} (${$t(`flight.sourceName.${data.label}`)})`
                              : data.label )
                            : $t(`promotionAg.${data.label}`) }}
                        </span>
                      </div>
                    </template>
                    <template #spinner="data">
                      <div
                        v-if="data.loading"
                        style="border-left-color: rgba(88, 151, 251, 0.71)"
                        class="vs__spinner"
                      />
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <!-- ANCHOR bookingClass -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.bookingClass') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.bookingClass"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    class="v-select-wrap"
                  >
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.groupClass') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.groupClass"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    class="v-select-wrap"
                  >
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR fareBasisCode -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.fareBasisCode') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.fareBasisCode"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    class="v-select-wrap"
                  >
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR Flight trips -->
              <b-col md="4">
                <InputMultipleAutoTaggable
                  :label="'flightTrip'"
                  :placeholder="'promotionAg.placeholder.flightTrip'"
                  :valueLength="3"
                  :valueMaxLength="7"
                  @updateValue="val => { promotionDataToAdd.flightTrips = val }"
                />
              </b-col>

              <!-- ANCHOR Flight types -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.flightTypes') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.flightType"
                    style="font-size: 1rem;"
                    :options="flightTypeOptionsForPromotionAg"
                    :reduce="val => val.value"
                    :filterable="false"
                    :placeholder="$t('placeholderSelect')"
                  >
                    <template #selected-option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class="d-block text-nowrap">
                          {{ $t(data.label) }}
                        </span>
                      </div>
                    </template>
                    <template #option="data">
                      <div class="d-flex-center justify-content-between">
                        <span class="d-block text-nowrap">
                          {{ $t(data.label) }}
                        </span>
                      </div>
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR Country trips -->
              <b-col md="4">
                <InputMultipleAutoTaggable
                  :label="'countryTrips'"
                  :placeholder="'promotionAg.placeholder.countryTrips'"
                  :valueLength="2"
                  :valueMaxLength="5"
                  @updateValue="val => { promotionDataToAdd.countryTrips = val }"
                />
              </b-col>

              <!-- ANCHOR countryDepartures -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.countryDepartures') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.countryDepartures"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    class="v-select-wrap"
                  >
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <!-- ANCHOR countryArrivals -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.countryArrivals') }}
                    </div>
                  </template>
                  <v-select
                    v-model="promotionDataToAdd.countryArrivals"
                    style="font-size: 1rem;"
                    multiple
                    taggable
                    :close-on-select="false"
                    :placeholder="$t('promotionAg.placeholder.selectOrInput')"
                    class="v-select-wrap"
                  >
                    <template #no-options>
                      {{ $t('promotionAg.placeholder.selectOrInput') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- ANCHOR Flight start date -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.flightStartTime') }}
                    </div>
                  </template>
                  <b-form-datepicker
                    v-model="promotionDataToAdd.flightStartTime"
                    :locale="$i18n.locale"
                    :placeholder="$t('placeholderSelect')"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :min="new Date()"
                    :max="promotionDataToAdd.flightEndTime ? new Date(promotionDataToAdd.flightEndTime) : null"
                    show-decade-nav
                    hide-header
                    boundary="window"
                    flip
                  />
                </b-form-group>
              </b-col>

              <!-- ANCHOR Flight end date -->
              <b-col md="4">
                <b-form-group>
                  <template #label>
                    <div>
                      {{ $t('promotionAg.flightEndTime') }}
                    </div>
                  </template>
                  <b-form-datepicker
                    v-model="promotionDataToAdd.flightEndTime"
                    :locale="$i18n.locale"
                    :placeholder="$t('placeholderSelect')"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    :min="promotionDataToAdd.flightStartTime ? new Date(promotionDataToAdd.flightStartTime) : new Date()"
                    show-decade-nav
                    hide-header
                    boundary="window"
                    flip
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <!-- !SECTION -->

        <!-- ANCHOR Action Buttons -->
        <div class="d-flex mt-1 justify-content-center">
          <!-- Back button -->
          <b-button
            variant="secondary"
            class="mr-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="backHandle"
          >
            {{ $t('back') }}
          </b-button>

          <!-- Clear button -->
          <b-button
            variant="danger"
            class="mx-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="pristine"
            @click="clearHandle"
          >
            {{ $t('clear') }}
          </b-button>

          <!-- Next button -->
          <b-button
            variant="info"
            class="ml-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="invalid"
            type="submit"
          >
            {{ $t('save') }}
          </b-button>
        </div>
      </b-form>
      <!-- !SECTION -->
    </validation-observer>
  </section>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import cloneDeep from 'lodash/cloneDeep'

import {
  typeOfDiscountOptions, typeOfDiscountPaidOptions, flightTypeOptionsForPromotionAg, typeOfDiscountStrategyOptions,
} from '@/constants/selectOptions'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import usePromotionAgHandle from '@promotionAg/usePromotionAgHandle'
import usePromotionAgAirlines from '@promotionAg/usePromotionAgAirlines'
import usePromotionAgCodeshare from '@promotionAg/usePromotionAgCodeshare'
import {
  onFocusSearchAndInputAgencies,
  onShownModalSearchAndInputAgencies,
} from '@promotionAg/usePromotionAgAgency'
import promotionAgStoreModule from '@promotionAg/promotionAgStoreModule'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
    BFormDatepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    InputMultipleAutoTaggable: () => import('../components/InputMultipleAutoTaggable.vue'),
    SearchAgenciesSelect: () => import('../components/SearchAgenciesSelect.vue'),
  },

  setup() {
    const PROMOTION_AG_APP_STORE_MODULE_NAME = 'app-promotionAg'

    // Register module
    if (!store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_AG_APP_STORE_MODULE_NAME, promotionAgStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_AG_APP_STORE_MODULE_NAME)
      }
    })

    const {
      allVnAirlines,
      airlineFilterOptions,
      airlineFilterOptionsWithAllAirlines,
      sourceFilterOptionsWithAllSources,
      createValidAirlineOption,
      loadingSources,
    } = usePromotionAgAirlines()

    const {
      codeshare,
      enableTaggable,
      onEnterCodeshare,
    } = usePromotionAgCodeshare()

    const blankPromotionData = {
      name: null,
      code: '',
      airline: [],
      source: [],
      discountAmount: null,
      discountPercent: null,
      discountType: 'VND',
      paidType: 'PREPAID',
      strategy: 'BY_TICKET_NUMBER',
      flightTrips: [],
      flightType: null,
      startTime: null,
      endTime: null,
      flightStartTime: null,
      flightEndTime: null,
      agencies: [],
      minimumPassenger: null,
      operator: [],
      bookingClass: [],
      groupClass: [],
      fareBasisCode: [],
      countryDepartures: [],
      countryArrivals: [],
      countryTrips: [],
      flightDesignators: [],
      excludedAgencies: [],
    }

    const promotionDataToAdd = ref(JSON.parse(JSON.stringify(blankPromotionData)))
    const resetPromotionData = () => {
      promotionDataToAdd.value = JSON.parse(JSON.stringify(blankPromotionData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPromotionData)
    const {
      addPromotion,
    } = usePromotionAgHandle()

    function nextHandle() {
      console.log({ payload: promotionDataToAdd.value })

      this.$bvModal.show('modal-api-loading')
      addPromotion(promotionDataToAdd.value).then(() => {
        this.$router.push({ name: 'apps-promotionAg-list' })
      }).finally(() => {
        this.$bvModal.hide('modal-api-loading')
      })
    }

    function backHandle() {
      this.$router.go(-1)
    }

    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    function handleChangeDiscountType(val) {
      if (val === 'VND') {
        promotionDataToAdd.value.discountAmount = promotionDataToAdd.value.discountPercent
      } else if (val === '%' && promotionDataToAdd.value.discountAmount >= 0 && promotionDataToAdd.value.discountAmount <= 100) {
        promotionDataToAdd.value.discountPercent = promotionDataToAdd.value.discountAmount
      } else {
        promotionDataToAdd.value.discountPercent = null
        promotionDataToAdd.value.discountAmount = null
      }
    }

    return {
      promotionDataToAdd,
      refFormObserver,
      getValidationState,
      cloneDeep,

      // select options
      typeOfDiscountOptions,
      typeOfDiscountPaidOptions,
      typeOfDiscountStrategyOptions,
      flightTypeOptionsForPromotionAg,

      // Methods
      backHandle,
      nextHandle,
      clearHandle,
      trimInput,
      upperCaseFormatter,
      handleChangeDiscountType,

      /* returned by usePromotionAgAirlines() */
      allVnAirlines,
      airlineFilterOptions,
      airlineFilterOptionsWithAllAirlines,
      sourceFilterOptionsWithAllSources,
      createValidAirlineOption,
      loadingSources,
      /* !returned by usePromotionAgAirlines() */

      /* returned by usePromotionAgCodeshare() */
      codeshare,
      enableTaggable,
      onEnterCodeshare,
      /* !returned by usePromotionAgCodeshare() */

      /* imported from '@promotionAg/usePromotionAgAgency' */
      // search and input Agencies
      onFocusSearchAndInputAgencies,
      onShownModalSearchAndInputAgencies,
      /* !imported from '@promotionAg/usePromotionAgAgency' */
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-wrap {
  .vs__selected-options {
    flex-wrap: wrap;
  }
}
</style>
