var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "pb-2"
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.nextHandle);
            }
          }
        }, [_c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('promotionAg.create.title')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1 mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.code'),
            "rules": "code|length:9"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.code')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "maxlength": "9",
                  "minlength": "9",
                  "lazy-formatter": "",
                  "disabled": true,
                  "formatter": _vm.upperCaseFormatter,
                  "placeholder": _vm.$t('promotionAg.placeholder.code'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToAdd.code,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToAdd, "code", $$v);
                  },
                  expression: "promotionDataToAdd.code"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.name'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.name')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "maxlength": "100",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.name'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToAdd.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToAdd, "name", $$v);
                  },
                  expression: "promotionDataToAdd.name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.promotionType')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.typeOfDiscountPaidOptions,
            "clearable": false,
            "disabled": false,
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.paidType,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "paidType", $$v);
            },
            expression: "promotionDataToAdd.paidType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.promotionStrategy')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.typeOfDiscountStrategyOptions,
            "clearable": false,
            "disabled": false,
            "label": "label",
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(data.label))) + " ")])];
            }
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.strategy,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "strategy", $$v);
            },
            expression: "promotionDataToAdd.strategy"
          }
        })], 1)], 1), _vm.promotionDataToAdd.discountType === 'VND' ? _c('b-col', {
          staticStyle: {
            "min-width": "20%"
          },
          attrs: {
            "md": "2"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.discount'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.discount')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "type": "number",
                  "number": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "30",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.discountAmount')
                },
                model: {
                  value: _vm.promotionDataToAdd.discountAmount,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToAdd, "discountAmount", $$v);
                  },
                  expression: "promotionDataToAdd.discountAmount"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1) : _c('b-col', {
          staticStyle: {
            "min-width": "20%"
          },
          attrs: {
            "md": "2"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.discount'),
            "rules": "required|maxValue:100|min_value:0"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.discount')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "type": "number",
                  "number": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "max": "100",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.discountPercent')
                },
                model: {
                  value: _vm.promotionDataToAdd.discountPercent,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToAdd, "discountPercent", $$v);
                  },
                  expression: "promotionDataToAdd.discountPercent"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": ""
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticStyle: {
                  "opacity": "0"
                }
              }, [_vm._v(" " + _vm._s("-") + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.typeOfDiscountOptions,
            "clearable": false,
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          on: {
            "input": _vm.handleChangeDiscountType
          },
          model: {
            value: _vm.promotionDataToAdd.discountType,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "discountType", $$v);
            },
            expression: "promotionDataToAdd.discountType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.startTime'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.startTime')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                attrs: {
                  "locale": _vm.$i18n.locale,
                  "placeholder": _vm.$t('placeholderSelect'),
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": new Date(),
                  "max": _vm.promotionDataToAdd.endTime ? new Date(_vm.promotionDataToAdd.endTime) : null,
                  "show-decade-nav": "",
                  "hide-header": "",
                  "boundary": "window",
                  "no-flip": ""
                },
                model: {
                  value: _vm.promotionDataToAdd.startTime,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToAdd, "startTime", $$v);
                  },
                  expression: "promotionDataToAdd.startTime"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.endTime'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.endTime')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-datepicker', {
                attrs: {
                  "locale": _vm.$i18n.locale,
                  "placeholder": _vm.$t('placeholderSelect'),
                  "date-format-options": {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },
                  "min": _vm.promotionDataToAdd.startTime ? new Date(_vm.promotionDataToAdd.startTime) : new Date(),
                  "show-decade-nav": "",
                  "hide-header": "",
                  "boundary": "window",
                  "no-flip": ""
                },
                model: {
                  value: _vm.promotionDataToAdd.endTime,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToAdd, "endTime", $$v);
                  },
                  expression: "promotionDataToAdd.endTime"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('promotionAg.minimumPassenger'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.minimumPassenger')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "number": "",
                  "maxlength": "5",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('promotionAg.placeholder.minimumPassenger'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.promotionDataToAdd.minimumPassenger,
                  callback: function callback($$v) {
                    _vm.$set(_vm.promotionDataToAdd, "minimumPassenger", $$v);
                  },
                  expression: "promotionDataToAdd.minimumPassenger"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.quotaAmount')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "number": "",
            "type": "number",
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('promotionAg.placeholder.quotaAmount')
          },
          model: {
            value: _vm.promotionDataToAdd.quotaAmount,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "quotaAmount", $$v);
            },
            expression: "promotionDataToAdd.quotaAmount"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.quotaCounter')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "type": "number",
            "number": "",
            "maxlength": "30",
            "lazy-formatter": "",
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('promotionAg.placeholder.quotaCounter')
          },
          model: {
            value: _vm.promotionDataToAdd.quotaCounter,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "quotaCounter", $$v);
            },
            expression: "promotionDataToAdd.quotaCounter"
          }
        })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('SearchAgenciesSelect', {
          attrs: {
            "label": 'agencyApply',
            "placeholder": 'promotionAg.allAgencies'
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.promotionDataToAdd.agencies = _vm.cloneDeep(val);
            }
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('SearchAgenciesSelect', {
          attrs: {
            "label": 'agenciesDeclined',
            "placeholder": 'placeholderSelect'
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.promotionDataToAdd.excludedAgencies = _vm.cloneDeep(val);
            }
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.operator')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "push-tags": "",
            "close-on-select": false,
            "taggable": _vm.enableTaggable,
            "options": _vm.airlineFilterOptions,
            "create-option": _vm.createValidAirlineOption,
            "reduce": function reduce(val) {
              return val.value;
            },
            "placeholder": _vm.$t('promotionAg.placeholder.operator')
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref2) {
              var attributes = _ref2.attributes,
                events = _ref2.events;
              return [attributes.type === 'checkbox' ? _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.codeshare,
                  expression: "codeshare"
                }],
                ref: "refSearchOrInputCodeshare",
                staticClass: "vs__search",
                attrs: {
                  "maxlength": "2",
                  "oninput": "this.value = this.value.toUpperCase()",
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.codeshare) ? _vm._i(_vm.codeshare, null) > -1 : _vm.codeshare
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterCodeshare();
                  },
                  "change": function change($event) {
                    var $$a = _vm.codeshare,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && (_vm.codeshare = $$a.concat([$$v]));
                      } else {
                        $$i > -1 && (_vm.codeshare = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.codeshare = $$c;
                    }
                  }
                }
              }, 'input', attributes, false), events)) : attributes.type === 'radio' ? _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.codeshare,
                  expression: "codeshare"
                }],
                ref: "refSearchOrInputCodeshare",
                staticClass: "vs__search",
                attrs: {
                  "maxlength": "2",
                  "oninput": "this.value = this.value.toUpperCase()",
                  "type": "radio"
                },
                domProps: {
                  "checked": _vm._q(_vm.codeshare, null)
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterCodeshare();
                  },
                  "change": function change($event) {
                    _vm.codeshare = null;
                  }
                }
              }, 'input', attributes, false), events)) : _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.codeshare,
                  expression: "codeshare"
                }],
                ref: "refSearchOrInputCodeshare",
                staticClass: "vs__search",
                attrs: {
                  "maxlength": "2",
                  "oninput": "this.value = this.value.toUpperCase()",
                  "type": attributes.type
                },
                domProps: {
                  "value": _vm.codeshare
                },
                on: {
                  "keydown": function keydown($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.onEnterCodeshare();
                  },
                  "input": function input($event) {
                    if ($event.target.composing) { return; }
                    _vm.codeshare = $event.target.value;
                  }
                }
              }, 'input', attributes, false), events))];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap",
                class: {
                  'font-weight-bold text-dark': !_vm.allVnAirlines.includes(data.value)
                }
              }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between",
                class: {
                  'font-weight-bold text-dark': !_vm.allVnAirlines.includes(data.value)
                }
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.label) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(data) {
              return [data.loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.notExistCodeshare')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.operator,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "operator", $$v);
            },
            expression: "promotionDataToAdd.operator"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.airline')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.airlineFilterOptionsWithAllAirlines,
            "multiple": "",
            "close-on-select": false,
            "reduce": function reduce(val) {
              return val.value;
            },
            "placeholder": _vm.$t('promotionAg.allAirlines')
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: " d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(data) {
              return [data.loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.airline,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "airline", $$v);
            },
            expression: "promotionDataToAdd.airline"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.source')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.sourceFilterOptionsWithAllSources,
            "multiple": "",
            "close-on-select": false,
            "reduce": function reduce(val) {
              return val.value;
            },
            "loading": _vm.loadingSources,
            "placeholder": _vm.$t('promotionAg.allAirlines')
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? _vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: " d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(data.value ? _vm.$te("flight.airlines.".concat(data.label)) ? "".concat(_vm.$t("flight.airlines.".concat(data.label)), " (").concat(_vm.$t("flight.sourceName.".concat(data.label)), ")") : data.label : _vm.$t("promotionAg.".concat(data.label))) + " ")])])];
            }
          }, {
            key: "spinner",
            fn: function fn(data) {
              return [data.loading ? _c('div', {
                staticClass: "vs__spinner",
                staticStyle: {
                  "border-left-color": "rgba(88, 151, 251, 0.71)"
                }
              }) : _vm._e()];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.source,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "source", $$v);
            },
            expression: "promotionDataToAdd.source"
          }
        })], 1)], 1)], 1), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.bookingClass')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput')
          },
          scopedSlots: _vm._u([{
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.bookingClass,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "bookingClass", $$v);
            },
            expression: "promotionDataToAdd.bookingClass"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.groupClass')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput')
          },
          scopedSlots: _vm._u([{
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.groupClass,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "groupClass", $$v);
            },
            expression: "promotionDataToAdd.groupClass"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.fareBasisCode')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput')
          },
          scopedSlots: _vm._u([{
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.fareBasisCode,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "fareBasisCode", $$v);
            },
            expression: "promotionDataToAdd.fareBasisCode"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('InputMultipleAutoTaggable', {
          attrs: {
            "label": 'flightTrip',
            "placeholder": 'promotionAg.placeholder.flightTrip',
            "valueLength": 3,
            "valueMaxLength": 7
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.promotionDataToAdd.flightTrips = val;
            }
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightTypes')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "options": _vm.flightTypeOptionsForPromotionAg,
            "reduce": function reduce(val) {
              return val.value;
            },
            "filterable": false,
            "placeholder": _vm.$t('placeholderSelect')
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('div', {
                staticClass: "d-flex-center justify-content-between"
              }, [_c('span', {
                staticClass: "d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.flightType,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "flightType", $$v);
            },
            expression: "promotionDataToAdd.flightType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('InputMultipleAutoTaggable', {
          attrs: {
            "label": 'countryTrips',
            "placeholder": 'promotionAg.placeholder.countryTrips',
            "valueLength": 2,
            "valueMaxLength": 5
          },
          on: {
            "updateValue": function updateValue(val) {
              _vm.promotionDataToAdd.countryTrips = val;
            }
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.countryDepartures')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput')
          },
          scopedSlots: _vm._u([{
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.countryDepartures,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "countryDepartures", $$v);
            },
            expression: "promotionDataToAdd.countryDepartures"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.countryArrivals')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "v-select-wrap",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "multiple": "",
            "taggable": "",
            "close-on-select": false,
            "placeholder": _vm.$t('promotionAg.placeholder.selectOrInput')
          },
          scopedSlots: _vm._u([{
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.promotionDataToAdd.countryArrivals,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "countryArrivals", $$v);
            },
            expression: "promotionDataToAdd.countryArrivals"
          }
        })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightStartTime')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-datepicker', {
          attrs: {
            "locale": _vm.$i18n.locale,
            "placeholder": _vm.$t('placeholderSelect'),
            "date-format-options": {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            "min": new Date(),
            "max": _vm.promotionDataToAdd.flightEndTime ? new Date(_vm.promotionDataToAdd.flightEndTime) : null,
            "show-decade-nav": "",
            "hide-header": "",
            "boundary": "window",
            "flip": ""
          },
          model: {
            value: _vm.promotionDataToAdd.flightStartTime,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "flightStartTime", $$v);
            },
            expression: "promotionDataToAdd.flightStartTime"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('promotionAg.flightEndTime')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-datepicker', {
          attrs: {
            "locale": _vm.$i18n.locale,
            "placeholder": _vm.$t('placeholderSelect'),
            "date-format-options": {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            },
            "min": _vm.promotionDataToAdd.flightStartTime ? new Date(_vm.promotionDataToAdd.flightStartTime) : new Date(),
            "show-decade-nav": "",
            "hide-header": "",
            "boundary": "window",
            "flip": ""
          },
          model: {
            value: _vm.promotionDataToAdd.flightEndTime,
            callback: function callback($$v) {
              _vm.$set(_vm.promotionDataToAdd, "flightEndTime", $$v);
            },
            expression: "promotionDataToAdd.flightEndTime"
          }
        })], 1)], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex mt-1 justify-content-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "secondary",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('b-button', {
          staticClass: "mx-1",
          attrs: {
            "variant": "danger",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "disabled": pristine
          },
          on: {
            "click": _vm.clearHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('clear')) + " ")]), _c('b-button', {
          staticClass: "ml-1",
          attrs: {
            "variant": "info",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "disabled": invalid,
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")])], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }