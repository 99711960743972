export async function onFocusSearchAndInputAgencies() {
  await this.$nextTick(() => {
    this.$refs.refShowSelectedAgencies.blur()
  })
  this.$root.$emit('bv::show::modal', 'id-modal-promotionag-select-ag')
}

export function onShownModalSearchAndInputAgencies() {
  this.$nextTick(() => {
    this.$refs.refSearchAndInputAgencies.focus()
  })
}
